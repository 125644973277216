import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";

import classNames from "classnames/bind";
import styles from "./index.module.scss";
import logo from "./images/logo.png";
import unesp from "./images/unesp.png";
import Bottom from "./bottom";
const _classNames = classNames.bind(styles);

export default function Footer() {
  return (
    <React.Fragment>
      <footer className={styles.footer}>
        <div className={styles.footerWidgets}>
          <Container>
            <Row>
              <Col md={3}>
                <div className={_classNames("widget", "widgetText")}>
                  <img src={logo} alt="QRES Logo" />
                  <ul>
                    <li className="address">
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                      Av. 18 de marzo # 5321, Col. las Águilas, Zapopan Jalisco.
                    </li>
                    <li className="phone">
                      <FontAwesomeIcon icon={faPhoneAlt} />
                      <a href="tel:3322439463">33 2243 9463</a> /
                      <a href="tel:3396883404">33 9688 3404</a> /
                      <a href="tel:3338018870">33 3801 8870</a>
                    </li>
                    <li className="email">
                      <FontAwesomeIcon icon={faEnvelope} />
                      <a href="mailto:info@qres.com.mx">info@qres.com.mx</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3}>
                <div
                  className={_classNames("widget", "widget_tweets", "clearfix")}
                >
                  <h5 className={styles.widgetTitle}>Enlaces Rapidos</h5>
                  <ul className={styles.linkLeft}>
                    <li>
                      <Link to="/">Inicio</Link>
                    </li>
                    <li>
                      <Link to="nosotros">Nosotros</Link>
                    </li>
                    <li>
                      <Link to="programas">Programas</Link>
                    </li>
                    <li>
                      <Link to="lista-de-cursos">Lista de cursos</Link>
                    </li>
                    <li>
                      <Link to="blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="clientes">Clientes</Link>
                    </li>
                  </ul>
                  <ul className={styles.linkRight}>
                    <li>
                      <Link to="contacto">Contacto</Link>
                    </li>
                    <li>
                      <a href="https://jezamkspc.com/">Grupo Jezamq</a>
                    </li>
                    <li>
                      <a href="https://jezamkspc.com/">Seguridad Jezamk</a>
                    </li>
                    <li>
                      <a href="https://visorspinteligente.mx/">
                        VisorSP Inteligente
                      </a>
                    </li>
                    <li>
                      <a href="https://www.quirmar.com/">Quirmar</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3}>
                <div className={_classNames("widget", "widgetText")}>
                  <h5 className={styles.widgetTitle}>Afiliación</h5>
                  <img src={unesp} alt="UNESP Logo" />
                </div>
              </Col>
              <Col md={3}>
                <div className={_classNames("widget", "widgetQuickContact")}>
                  <h5 className={styles.widgetTitle}>Contacto Rapido</h5>
                  <Row>
                    <Col md={12}>
                      <form
                        action=""
                        className={styles.flatContactForm}
                        id="contactform"
                      >
                        <input
                          type="email"
                          defaultValue=""
                          tabIndex="1"
                          placeholder="Email"
                          required
                        />
                        <textarea
                          className="type-input"
                          tabIndex="2"
                          placeholder="Mensaje"
                          id=""
                          required
                        ></textarea>
                        <button className={styles.flatButton}>
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </button>
                      </form>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
      <Bottom />
    </React.Fragment>
  );
}
