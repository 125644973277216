import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slideshow from "../../components/Slideshow/index";
import { Col, Container, Row } from "react-bootstrap";
import cursos from "./images/cursos.jpg";
import CoursesSlider from "../../components/CoursesSlider";
import { Parallax } from "react-parallax";
import bgParalax1 from "./images/bg-parallax1.jpg";
import chunk from "lodash/chunk";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import MicroSlider from "../../components/MicroSlider";
import CountUp from "react-countup";
import { InView } from "react-intersection-observer";
import { remote } from "../../services/api";
import useFetch from "../../hooks/useFetch";
import concat from "lodash.concat";
import { defaultBlogData } from "../../shared/data";
import WhatsappButton from "../../components/WhatsappButton/index";
const _classNames = classNames.bind(styles);

export default function Home() {
  const [slideshow, setDataSlideshow] = useState([]);
  const [programs, setDataPrograms] = useState([]);
  const [news, setDataNews] = useState([]);
  const [clients, setDataClients] = useState([]);

  const { response: slideshowEndpoint } = useFetch({
    api: remote,
    url: "/slideshows",
  });

  const { response: programsEndpoint } = useFetch({
    api: remote,
    url: "/programs/?limit=6",
  });

  const { response: newsEndpoint } = useFetch({
    api: remote,
    url: "/news/?field=date&order=desc&limit=3",
  });

  const { response: clientsEndpoint } = useFetch({
    api: remote,
    url: "/clients?limit=12",
  });

  useEffect(() => {
    if (slideshowEndpoint !== null) {
      setDataSlideshow(slideshowEndpoint);
    }

    if (programsEndpoint !== null) {
      if ((programsEndpoint.data || []).length === 3) {
        programsEndpoint.data = concat(
          programsEndpoint.data,
          programsEndpoint.data
        );
      }
      setDataPrograms(programsEndpoint);
    }

    if (newsEndpoint !== null) {
      setDataNews(newsEndpoint);
    }

    if (clientsEndpoint !== null) {
      if ((clientsEndpoint.data || []).length === 6) {
        clientsEndpoint.data = concat(
          clientsEndpoint.data,
          clientsEndpoint.data
        );
      }
      setDataClients(clientsEndpoint);
    }
  }, [slideshowEndpoint, programsEndpoint, newsEndpoint, clientsEndpoint]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let rows = [];

  const defaultBlog = defaultBlogData();

  return (
    <React.Fragment>
      <Slideshow content={slideshow} />
      <div className={_classNames("flatRow", "course", "rowBg")}>
        <Container>
          <Row>
            <Col md={7} sm={12} className={styles.flatPdr100}>
              <h1 className={styles.titleCourse}>Instituto QRES</h1>
              <p className={styles.flatLh25}>
                <strong>
                  Instituto QRES Capacitación Evaluación Y Certificación En
                  Seguridad Integral S. De. R.L. De .C.V.{" "}
                </strong>{" "}
                Nació de la imperiosa necesidad de capacitar a todo el personal
                de seguridad privada misma que está legalmente constituida para
                llevar a cabo sus objetos sociales. Así mismo cuenta con
                autorización y registro de la secretaria de trabajo y previsión
                social como agente capacitador externo con el numero
                qce-190321-tw7-0013.
              </p>
              <div className={styles.flatSpacer}></div>
              <div className={styles.flatButtonContainer}>
                <Link
                  className={_classNames("flatButton", "blue")}
                  to="nosotros"
                >
                  NOSOTROS
                </Link>
              </div>
            </Col>
            <Col md={5} sm={12}>
              <img src={cursos} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className={_classNames("flatRow", "popularCourse")}>
        <Container>
          <div className={styles.flatTitleSection}>
            <h1 className={styles.title}>ALGUNOS CURSOS</h1>
          </div>
          {(programs.data || []).length > 0 && (
            <CoursesSlider content={programs} />
          )}
        </Container>
      </div>
      <Parallax
        bgImage={bgParalax1}
        bgImageAlt="Parallax background"
        className={_classNames("flatRow", "joinUs")}
        strench={800}
      >
        <div className={_classNames("overlay", "bg222")}>
          <Container>
            <Row>
              <div className={styles.counterContent}>
                <span className={styles.counterPrefix}>Más de</span>

                <InView>
                  {({ inView, ref, entry }) => (
                    <div ref={ref} className={styles.numbCounter}>
                      {inView && (
                        <CountUp start={0} end={150} delay={0}>
                          {({ countUpRef }) => (
                            <div
                              className={styles.numbCount}
                              ref={countUpRef}
                            ></div>
                          )}
                        </CountUp>
                      )}
                    </div>
                  )}
                </InView>

                <span className={styles.counterSuffix}>Cursos</span>
              </div>
              <p>
                Se imparte capacitación con instructores externos,
                independientes e internos, ex militares y profesores, con basta
                experiencia ininterrumpidamente registrados ante la Secretaria
                de Trabajo y Previsión Social desde 1996, cuenta con
                certificaciones y diplomados en el extranjero en los países como
                son: Israel, Colombia, Panamá, Estados unidos, Bélgica,
                Alemania. En sistemas de seguridad integral en general.
              </p>
              <Link
                to="contacto"
                className={_classNames("flatButton", "centerBtn")}
              >
                Ver Cursos
              </Link>
            </Row>
          </Container>
        </div>
      </Parallax>
      <div className={_classNames("flatRow", "lastestNew")}>
        <Container>
          <div className={styles.flatTitleSection}>
            <h1 className={styles.title}>ULTIMAS NOTICIAS</h1>
          </div>
          {(news.data || []).forEach((item, key) => {
            rows.push(
              <Col
                md={4}
                xs={12}
                sm={6}
                className={_classNames("post", "flatHoverZoom")}
                key={key}
              >
                <div className={styles.featuredPost}>
                  <div className={styles.entryImage}>
                    <Link
                      to={{
                        pathname: `blog/detalle/${item.id}`,
                        state: {
                          data: defaultBlog,
                        },
                      }}
                    >
                      <img src={item.image} alt="" />
                    </Link>
                  </div>
                </div>
                <div className={styles.datePost}>
                  <span className={styles.numb}>{item.day}</span>
                  <span>{item.month}</span>
                </div>
                <div className={styles.contentPost}>
                  <h2 className={styles.titlePost}>
                    <Link
                      to={{
                        pathname: `blog/detalle/${item.id}`,
                        state: {
                          data: defaultBlog,
                        },
                      }}
                    >
                      {item.title}
                    </Link>
                  </h2>
                  <div className={styles.entryContent}>
                    <p>{item.short_description}</p>
                  </div>
                </div>
              </Col>
            );
          })}
          {chunk(rows, 3).map(function (group, key) {
            return (
              <Row className={styles.postLastestNew} key={key}>
                {group}
              </Row>
            );
          })}
        </Container>
      </div>
      <section className={_classNames("flatRow", "partners")}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.pageTitleHeading}>
                <h2 className="title">CLIENTES ACTIVOS</h2>
              </div>
            </Col>
          </Row>
          {(clients.data || []).length > 0 && (
            <MicroSlider content={clients} items={6} />
          )}
        </Container>
      </section>
      <WhatsappButton />
    </React.Fragment>
  );
}
