import React, { useState } from "react";
import bgParalax from "./images/bg-parallax8.jpg";
import iconPoint from "./images/c1.png";
import iconContact from "./images/c2.png";
import iconEmail from "./images/c3.png";
import SectionHeader from "../../components/SectionHeader/index";
import styles from "./index.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faKey,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames/bind";
import WhatsappButton from "../../components/WhatsappButton/index";
const _classNames = classNames.bind(styles);

export default function Contacts(props) {
  const [contact, setDataContact] = useState({
    name: "",
    emal: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (event) => {
    setDataContact({
      ...contact,
      [event.target.name]: event.target.value,
    });
  };

  const sendForm = (e) => {
    e.preventDefault();
    console.log(contact);
  };

  return (
    <React.Fragment>
      <SectionHeader
        bgImage={bgParalax}
        strench={250}
        title={"CONTACTO"}
        parent={{ title: "Contacto" }}
      />
      <section
        className={_classNames(
          "mainContent",
          "blogPosts",
          "flatRow",
          "courseGrid"
        )}
      >
        <Container className={_classNames("container", "customContainer")}>
          <section class={_classNames("flatRow", "contactPage", "padTop134")}>
            <Container>
              <Row>
                <Col md={4}>
                  <div className={styles.contactContent}>
                    <div className={styles.contactAddress}>
                      <div className={styles.style1}>
                        <img src={iconPoint} alt="Ubicacion"></img>
                      </div>
                      <div className={styles.details}>
                        <h5>Ubicación</h5>
                        <p>Av 18 de Marzo #5321, Col. las Águilas</p>
                        <p>45080, Zapopan Jalisco</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className={styles.contactContent}>
                    <div className={styles.contactAddress}>
                      <div className={styles.style1}>
                        <img src={iconContact} alt="Contacto"></img>
                      </div>
                      <div className={styles.details}>
                        <h5>Contacto</h5>
                        <p>Ventas: 33 2243 9463</p>
                        <p>Oficinas: 33 9688 3404 y 33 3801 8870</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className={styles.contactContent}>
                    <div className={styles.contactAddress}>
                      <div className={styles.style1}>
                        <img src={iconEmail} alt="Correo"></img>
                      </div>
                      <div className={styles.details}>
                        <h5>Correo</h5>
                        <p>
                          <a href="mailto:ventas@qres.com.mx">
                            Ventas@qres.com.mx
                          </a>
                        </p>
                        <p>
                          <a href="mailto:info@qres.com.mx">Info@qres.com.mx</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <div class={_classNames("flatSpacer", "d74px")}></div>
              </Row>
              <div class={_classNames("commentRespond", "contact", "style2")}>
                <h1 class={_classNames("title", "commentTitle")}>
                  Deja un Mensaje
                </h1>
                <form class={_classNames("flatContactForm", "style2")}>
                  <div class={_classNames("field", "clearfix")}>
                    <div class={styles.wrapTypeInput}>
                      <div class={_classNames("inputWrap", "name")}>
                        <FontAwesomeIcon icon={faUser} />
                        {/* We need add state from this field */}
                        <input
                          type="text"
                          onChange={handleInputChange}
                          tabindex="1"
                          placeholder="Nombre"
                          name="name"
                          id="name"
                          required
                        ></input>
                      </div>
                      <div class={_classNames("inputWrap", "email")}>
                        <FontAwesomeIcon icon={faEnvelope} />
                        {/* We need add state from this field */}
                        <input
                          type="text"
                          tabindex="2"
                          placeholder="Email"
                          name="email"
                          id="email"
                          onChange={handleInputChange}
                          required
                        ></input>
                      </div>
                      <div class={_classNames("inputWrap", "last", "Subject")}>
                        <FontAwesomeIcon icon={faKey} />
                        {/* We need add state from this field */}
                        <input
                          type="text"
                          tabindex="3"
                          placeholder="Asunto (Opcional)"
                          name="subject"
                          id="subject"
                          onChange={handleInputChange}
                          required
                        ></input>
                      </div>
                    </div>
                    <div class={styles.textareaWrap}>
                      <FontAwesomeIcon icon={faComment} />
                      {/* We need add state from this field */}
                      <textarea
                        class={styles.typeInput}
                        tabindex="3"
                        placeholder="Mensaje"
                        name="message"
                        id="message-contact"
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div class={styles.submitWrap}>
                    {/* We need create onClick function and prop */}
                    <button
                      class={_classNames("flatButton", "bgOrange")}
                      onCLick={sendForm}
                    >
                      Enviar Mensaje
                    </button>
                  </div>
                </form>
              </div>
            </Container>
          </section>

          <section>
            <Container fluid>
              <iframe
                title="Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3734.077935436683!2d-103.41378028508105!3d20.62567988621816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ac4e1d710831%3A0x7c3b24cbf46194b5!2sAv%2018%20de%20Marzo%205321%2C%20Las%20%C3%81guilas%2C%2045080%20Zapopan%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1613962271304!5m2!1ses-419!2smx"
                width="100%"
                height="450"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </Container>
          </section>
        </Container>
      </section>
      <WhatsappButton />
    </React.Fragment>
  );
}
