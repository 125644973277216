import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import styles from "./index.module.scss";

export default function Bottom() {
  return (
    <div className={styles.bottom}>
      <Container>
        <ul className={styles.flatSocialsV1}>
          <li>
            <a
              href="https://www.facebook.com/institutoqres"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon size="lg" icon={faFacebookF} />
            </a>
          </li>
          <li>
            <Link to="">
              <FontAwesomeIcon size="lg" icon={faInstagram} />
            </Link>
          </li>
          <li>
            <Link to="">
              <FontAwesomeIcon size="lg" icon={faLinkedinIn} />
            </Link>
          </li>
          <li>
            <Link to="">
              <FontAwesomeIcon size="lg" icon={faYoutube} />
            </Link>
          </li>
        </ul>
        <Row>
          <Col md={6} className={styles.containerBottom}>
            <div className={styles.copyright}>
              <p>
                © 2020 QRES. Todos los derechos reservados. Diseño por:{" "}
                <a
                  href="https://www.dscloud.mx"
                  target="_blank"
                  rel="noreferrer"
                >
                  Dscloud
                </a>
                .
              </p>
            </div>
          </Col>
          <Col md={6} className={styles.containerBottom}>
            <div className={styles.copyright}>
              <Link to="aviso-de-privacidad">Aviso de privacidad</Link> |{" "}
              <Link to="terminos-y-condiciones">Terminos y condiciones</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
